












































































































































































































































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class BillDetail extends mixins(Navigation, CustomDate) {
  loader = false;
  dialogPaymentDetail = false;
  paymentPreview = {};

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("bill/getBill", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.bd-error-1") + this.$route.params.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/investments",
      icon: "mdi-clipboard-text",
      title: `${this.$tc("Views.sn-1")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get bill() {
    return this.$store.getters["bill/getBill"];
  }

  private openPaymentDetail(payment: any) {
    this.paymentPreview = payment;
    this.dialogPaymentDetail = true;
  }

  private closePaymentDetail() {
    this.paymentPreview = {};
    this.dialogPaymentDetail = false;
  }

  private checkPreviewType(previewType: string): boolean {
    let type = previewType.toLowerCase();

    if (
      type == ".jpeg" ||
      type == ".png" ||
      type == ".jpg" ||
      type == ".svg" ||
      type == ".webm"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
